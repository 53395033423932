import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Chat from './Chat';
import Loading from './loading';
import Button from 'components/forms/Button';
import { AnchorLink, ConditionalLink } from 'components/Link';
import { useAnalytics } from 'context/AnalyticsProvider';
import Error from 'components/error/Error';
import { useAdBlockChecker } from 'context/useAdBlockChecker';
import Snackbar from 'components/Snackbar';
import useWindow from 'context/useWindow';

//#region
const ChatClosed = styled.p`
	margin: 0;
	flex-grow: 1;
`;
//#endregion

export default function IntercomChatCard({ title }) {
	const { intercomReady } = useAnalytics();
	const adblockDetected = useAdBlockChecker();
	const { browser } = useWindow();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [showAdBlockAlert, setShowAdBlockAlert] = useState(false);

	useEffect(() => {
		setLoading(!intercomReady);
		if (intercomReady) setError(false);
	}, [intercomReady]);

	return (
		<>
			<Chat
				title={title}
				open={!loading && intercomReady}
				statusText={
					!loading && intercomReady
						? 'Kundebehandler er tilgjengelig for chat'
						: 'Ingen kundebehandlere er tilgjengelige for chat'
				}>
				{loading ? (
					<Loading />
				) : (
					<>
						{intercomReady ? (
							<>
								<Button
									title={`Start Chat med ${title}`}
									onClick={async () => {
										setError(false);
										if (!window.Intercom) {
											console.log(
												'Intercom SDK not loaded'
											);
											setError(true);
											return;
										}

										if (
											adblockDetected &&
											!window.Intercom
										) {
											setShowAdBlockAlert(true);
										} else {
											setShowAdBlockAlert(false);
											window?.Intercom('showNewMessage');
										}
									}}>
									Start Chat
								</Button>
								{error && !loading && (
									<Error
										style={{ marginTop: '20px' }}
										resolver="IntercomChatCard"
										errorMessage="Intercom SDK not loaded"
										contactType="phone"
										bg={false}
										centered={true}
									/>
								)}
							</>
						) : (
							<ChatClosed>
								Ingen kundebehandlere tilgjengelig for chat.{' '}
								<br />
								Ring oss gjerne på{' '}
								<AnchorLink
									href="tel:004774150200"
									title="Ring kundeservice på telefon +47 741 50 200">
									+47 741 50 200
								</AnchorLink>
							</ChatClosed>
						)}
					</>
				)}
			</Chat>

			{!loading && adblockDetected && showAdBlockAlert && (
				<Snackbar
					data-cy="intercom-chat-card-adblock-detected"
					messages={[
						{
							_id: 'adblock-detected',
							text: (
								<>
									<span>
										Det ser ut som du har AdBlock aktivert
										på nettleseren din 😢. For å kunne
										chatte med oss, må du deaktivere AdBlock
										/ annonseblokkering.{' '}
										{browser &&
											browser !== 'Unknown' &&
											browserAdBlockDocs[browser] && (
												<ConditionalLink
													to={
														browserAdBlockDocs[
															browser
														]
													}
													clickable="true"
													title={`Gå til artikkel om hvordan du kan deaktiver AdBlock for ${browser}`}>
													Se hvordan det kan gjøres i{' '}
													{browser}.
												</ConditionalLink>
											)}
									</span>
								</>
							),
							category: 'alert',
						},
					]}
					onClose={() => {
						setShowAdBlockAlert(false);
					}}
				/>
			)}
		</>
	);
}

const browserAdBlockDocs = {
	Edge: 'https://support.microsoft.com/nb-no/microsoft-edge/finn-ut-mer-om-sporingshindring-i-microsoft-edge-5ac125e8-9b90-8d59-fa2c-7f2e9a44d869',
	Chrome: 'https://support.google.com/chrome/answer/7632919?hl=no',
	Firefox:
		'https://support.mozilla.org/no/kb/deaktiver-eller-fjern-tilleggsprogrammer',
	Safari: 'https://support.apple.com/no-no/102524',
	Opera: 'https://help.opera.com/en/latest/security-and-privacy/',
};
